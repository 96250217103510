@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@300&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%; /* 16px x 62.5 = 10px = 1rem */
  box-sizing: border-box;
}
body {
  background-color:#000005;
  color:#fff;
  margin: 0;
  height: 100vh;
  font-size: 1.6rem;
  font-family: 'Crimson Pro', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}
/* Video */
.player-wrapper {
  margin-top:40px;
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
/* Layout */
.grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
}
header {
  grid-area: header;
  background-color: #121212;
  height:100px;
}

.logo h1{
font-size:1.8rem;
}
main {
  grid-area: main;
  padding: 1.5rem;
}
footer {
  grid-area: footer;
  background-color: #171717;
  color: #ffffff;
  text-align:center
}
.footer {
  position: fixed;
            padding: 10px ;
            bottom: 0;
            width: 100%;       /* Footer height */
}
/* Common */

h2 {
  font-size: 2.6rem;
  padding: 0.2rem 0;
}
h3{font-size: 4rem;
  
}
.logo {
font-size:1.8rem;
letter-spacing: .5rem;}
a {
  text-decoration: none;
color:#fff
}

.show-title {
  font-size:3rem
}


ul {
  padding: 0;
  margin-left:2rem;
  list-style-type: none;
}
li {
  margin-top: 1rem;
}
input,
select,
textarea,
button {
  padding: 1rem;
  border-radius: 0.2rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  background-color: #f8f8f8;
  cursor: pointer;
}
input:hover,
select:hover,
textarea:hover,
button:hover {
  border: 0.1rem #404040 solid;
}
button.primary {
  color:#fff;
  background-color: #1d1c1c;
}
button.block {
  width: 100%;
}
button.small {
  font-size: 1.2rem;
}
/* Header */
header a {
  color: #ffffff;
  padding: 1rem;
}
a.brand {font-size:3rem;
  color: #ffffff;
}
header a:hover { 
  color:red;
  
}
a.brand:hover {
  color:red;
  
}
.badge {
  background-color: #2b2828;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin:2rem;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-content: flex-start;
}
.col-1 {
  flex: 1 1 25rem;
}
.col-2 {
  flex: 2 1 50rem;
}
.col-3 {
  flex: 32 1 75rem;
}
.min-30 {
  min-width: 30rem;
}
.p-1 {
  padding: 1rem;
}
.about{
 text-align:center ;

  padding:10rem;
  background-image: "images/night.jpg";
  background-repeat: no-repeat;
  background-size: 100%;
  padding:1rem;
  font-size:2rem;
}
.contact{
  text-align:center ;
 min-height: 100%;
   padding:10rem;

   padding:1rem;
   font-size:2rem;
 }
.link a {color :red;}
/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transform: translateX(-30rem);
  transition: all 0.5s;
}
aside.open {
  transform: translateX(0);
}
button.open-sidebar {
  font-size: 3rem;
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  background: none;
  color: #ffffff;
  cursor: pointer;
}
button.open-sidebar:hover {
  border-color: #ffffff;
}
aside ul {
  padding: 0;
  list-style: none;
}
aside li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
button.close-sidebar {
  padding: 0.3rem 0.8rem;
}

/* Image */
img {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
img.small {
  max-width: 5rem;
  width: 100%;
}
img.medium {
  max-width: 29rem;
  width: 100%;
}
img.large {
  width: 100%;
}

/* Card */
content {
  box-shadow: 0 .125rem .25rem 0 rgba(0,0,0,.1);
  transition: transform 1s ease;
}

.pseudo-hover:hover {
  transform: translateY(-.35em) scale(1.02);
}

.pseudo-hover {
  position: relative;
}

.pseudo-hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  box-shadow: 0 .5rem 3rem -.25em rgba(0,0,0,.3);
  transition: opacity 1s ease;
}

.pseudo-hover:hover::after {
  opacity: 1;
}
.card:hover,
  .card:focus,
  .card:active {
    color: #e93a0f;
    border-color: #13b8b8;
   
  }
.card {
  border: 0.1rem #ccc solid;
  box-shadow: 0 .5rem 3rem -.25em rgba(0,0,0,.9);
  background-color: #212121;
  border-radius: 0.35rem;
  
  margin: 2rem;
}
.card-body h2{
  font-size:1.8rem
}
.card-body {text-align:center;
  padding: 0.1rem 0.9rem;
}
.card-body > * {
  margin-bottom: 0.5rem;
}
.price {
  font-size: 2rem;
}
/* Rating */
.rating span {
  color: #f0c040;
  margin: 0.1rem;
}
.rating span:last-child {
  color: #404040;
}
/* Alert */
.loading {
  display: block !important;
}
.success {
  color: #20a020;
}
.danger {
  color: #a02020;
}
.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}
/* Form */
.form {
  max-width: 60rem;
  margin: 0 auto;
}
.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
.form label {
  margin: 1rem 0;
}
/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  padding: 1rem;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}
/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}
/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}
.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}
/* Carousel */
.carousel .slide img {
  max-width: 30rem;
}

/* Search */
.search button {
  border-radius: 0 0.5rem 0.5rem 0;
  border-right: none;
  margin-right: 0.5rem;
}
.search input {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none;
  margin-left: 0.5rem;
}
.active {
  font-weight: bold;
}

/* Map */
.full-container {
  margin: -1rem;
  height: calc(100% + 2rem);
}
.map-input-box {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0.5rem auto;
  width: 25rem;
  height: 4rem;
  display: flex;
}
.map-input-box input {
  border-radius: 1rem 0 0 1rem;
  border-right: 0;
}
.map-input-box button {
  border-radius: 0 1rem 1rem 0;
  border-left: 0;
}
/* Pagination */
.pagination a {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
  font-weight: bold;
}
.offAir{display:none}
.date a:hover{ font-size:2rem;
  color:red
 }

.gradient-text-large {
  font-size:4rem;

}

.component-wrapper {
  width: 40rem;
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  border: 0.1rem #ccc solid;
  box-shadow: 0 .5rem 3rem -.25em rgba(0,0,0,.8);

  border-radius: 0.5rem;
}

.universe {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

h1 {
  font-size: 1.3rem !important;
}

.tag {
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-family: 'Apercu';
  padding: 0.5rem;
  margin: 0.5rem;
  background: whitesmoke;
  color: #424242;
}
.contain{
  padding:80px
}
.contact{min-height:50vh;
}
.contact a {color:red}
.inside{
  text-align:center;
 max-width:800px;
color:white;
margin:0 auto;
padding:40px
}
.tub{
  min-height:80vh;
  text-align:center;
padding:50px;
color:white
}
.tubLink  {
  color:red
}
p {
  padding:10px
}
.preview {
  transform: translateX(-0.70rem);
  width: 5rem;
  margin-top: -2.75rem;
  display: block;
  color: #424242;
  background: whitesmoke;
}
.live h2 {font-size:2rem}
.offAir {display:none}













.carousel-image {
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
  .carousel-container {
    position: relative;
    margin: auto;
  }
  
  .carousel-item {
    width: 100%;
    height: 100%;
  }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  .prev {
    left: 0;
    border-radius: 3px 0 0 3px;
  }
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }
  
  /* Caption text */
  .carousel-caption-bottom {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
  }
  .carousel-caption-center {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 50%;
    top: 45%;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
  }
  
  .carousel-caption-top {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    top: 8px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
  }
  .dots {
    margin-top: 10px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
  .slide-number {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  .dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .active, .dot:hover {
    background-color: #717171;
  }
  
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }
  .pause-icon {
    position: absolute;
    /* top: 38%; */
    left: 15px;
    bottom: 2px;
    /* right: 50%; */
    opacity: 1;
  }
  
  .pause {
    -webkit-animation-name: pause;
    -webkit-animation-duration: 0.5s;
    animation-name: pause;
    animation-duration: 0.5s;
  }
  
  .bar {
    width: 100%;
    background-color: #ddd;
    position: absolute;
      bottom: 0px;
      border-radius: 0 0 10px;
  }
  
  .progress {
    width: 1%;
    height: 5px;
    background-color: #042baa;
  }
  
  .thumbnails {
    display: flex;
    margin-top: 10px;
    align-items: center;
    overflow: scroll;
  }
  .thumbnails::-webkit-scrollbar {
    display: none;
  }
  .thumbnail {
    margin: 0 5px;
  }
  .active-thumbnail {
    border: #476ff1 solid 3px;
  }
  @keyframes pause {
    0% {opacity: .2; }
    90% {opacity: 1; }
    
  }
  
  @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  @media only screen and (max-width: 500px) {
    .prev,
    .next,
    .carousel-caption-bottom, 
    .carousel-caption-center, 
    .carousel-caption-top, 
    .slide-number 
    {
      font-size: 20px !important;
    }
    .dot {
      height: 4px;
      width: 4px;
    }
    .carousel-container{
      max-height: 250px !important;
    }
    .thumbnail {
      max-width: 70px;
    }
    .carousel-caption-bottom{
      bottom: 25px;
    }
  }
.navbar {
  width: 100%;
  background-color: 00004E;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.5rem;
  z-index: 50;
}
.scrolled{
  position: fixed;
  top: 0;
  left: 0;
  background-color: lightblue;
}
.navbar__logo h2 {
  color: #f4f4f4;
 
  font-size: 4rem;
  cursor: pointer;
}
.navbar__logo h2:hover{color:rgb(169, 169, 172)}
.navbar__links {
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar__links > li {
  padding-left: 1.5rem;
}

.navbar__links > li > a {
  text-decoration: none;
  color: #f4f4f4;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
}
.navbar__links > li > a:hover{
 color:hsl(240, 1%, 43%);

}
.navbar__links > li > a > span {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.cart__link {
  background: #333;
  padding: 10px;
  border-radius: 8px;
}

.cart__link:hover {
  background: #dd219e;
  color: #f4f4f4;
}

.cartlogo__badge {
  width: 30px;
  height: 30px;
  background-color: #f4f4f4;
  border-radius: 50%;
  margin-left: 8px;
  color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.hamburger__menu {
  display: none;
  width: 30px;
  height: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.hamburger__menu:hover > div {
  background: #eb062c;
}

.hamburger__menu > div {
  width: 100%;
  height: 3px;
  background: #f4f4f4;
}

@media (max-width: 960px) {
  .navbar__links {
    display: none;
  }

  .hamburger__menu {
    display: flex;
  }
}

@media (max-width: 500px) {
  .navbar__logo h2 {
    font-size: 2.4rem;
  }
}

.sidedrawer {
  width: 60%;
  background: rgba(252, 251, 251, 0.7);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  transform: translateX(-100%);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidedrawer.show {
  transform: translateX(0);
}

.sidedrawer__links {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.sidedrawer__links > li {
  display: flex;
  align-items: center;
}

.sidedrawer__links > li > a:hover {
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #f3ec78, #eb0d4c);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  color: #f4f4f4;
}

.sidedrawer__links > li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1 1;
  padding: 1rem;
  text-decoration: none;
  color: #171717;
  font-size: 2.6rem;
}

.sidedrawer__links > li > a > span {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.sidedrawer__cartbadge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #171717;
  border-radius: 50%;
  margin-left: 8px;
  color: #fff;
  font-size: 1rem;
}

.sidedrawer__links > li > a:hover .sidedrawer__cartbadge {
  background-color: #fff;
  color: #171717;
}

@media (min-width: 960px) {
  .sidedrawer {
    display: none;
  }
}

.backdrop {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
}

