.sidedrawer {
  width: 60%;
  background: rgba(252, 251, 251, 0.7);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  transform: translateX(-100%);
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidedrawer.show {
  transform: translateX(0);
}

.sidedrawer__links {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.sidedrawer__links > li {
  display: flex;
  align-items: center;
}

.sidedrawer__links > li > a:hover {
  background-color: #f3ec78;
  background-image: linear-gradient(45deg, #f3ec78, #eb0d4c);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  color: #f4f4f4;
}

.sidedrawer__links > li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  padding: 1rem;
  text-decoration: none;
  color: #171717;
  font-size: 2.6rem;
}

.sidedrawer__links > li > a > span {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.sidedrawer__cartbadge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #171717;
  border-radius: 50%;
  margin-left: 8px;
  color: #fff;
  font-size: 1rem;
}

.sidedrawer__links > li > a:hover .sidedrawer__cartbadge {
  background-color: #fff;
  color: #171717;
}

@media (min-width: 960px) {
  .sidedrawer {
    display: none;
  }
}
