.navbar {
  width: 100%;
  background-color: 00004E;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.5rem;
  z-index: 50;
}
.scrolled{
  position: fixed;
  top: 0;
  left: 0;
  background-color: lightblue;
}
.navbar__logo h2 {
  color: #f4f4f4;
 
  font-size: 4rem;
  cursor: pointer;
}
.navbar__logo h2:hover{color:rgb(169, 169, 172)}
.navbar__links {
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar__links > li {
  padding-left: 1.5rem;
}

.navbar__links > li > a {
  text-decoration: none;
  color: #f4f4f4;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
}
.navbar__links > li > a:hover{
 color:hsl(240, 1%, 43%);

}
.navbar__links > li > a > span {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.cart__link {
  background: #333;
  padding: 10px;
  border-radius: 8px;
}

.cart__link:hover {
  background: #dd219e;
  color: #f4f4f4;
}

.cartlogo__badge {
  width: 30px;
  height: 30px;
  background-color: #f4f4f4;
  border-radius: 50%;
  margin-left: 8px;
  color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.hamburger__menu {
  display: none;
  width: 30px;
  height: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.hamburger__menu:hover > div {
  background: #eb062c;
}

.hamburger__menu > div {
  width: 100%;
  height: 3px;
  background: #f4f4f4;
}

@media (max-width: 960px) {
  .navbar__links {
    display: none;
  }

  .hamburger__menu {
    display: flex;
  }
}

@media (max-width: 500px) {
  .navbar__logo h2 {
    font-size: 2.4rem;
  }
}
